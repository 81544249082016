header {
    height: 100vh;
    padding-top: 12rem;
    overflow: hidden;
    background-image: url("https://i.imgur.com/Tyz6ij3.jpg");
    background-size: cover;

}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

.javier {
    font-size: 4rem;
}

.dev {
    color:white;
}


/* -----------CTA ---------------*/

.cta {
    margin-top: 7.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* -----------HEADER SOCIALS ---------------*/

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:0.8rem;
    position:absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content:'';
    width: 1px;
    height: 2rem;
    background-color: var(--color-primary);
}

/* -----------ME ---------------*/

/* .javier {
    text-shadow: red -2px 0,
    var(--color-primary) 2px 0;
} */

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/* -----------SCROLL DOWN ---------------*/
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* --------------Media queries (MEDIUM Devices) ---------  */
@media screen and (max-width: 1024px) {

    .javier {
        font-size: 3rem;
    }
    
}

/* --------------Media queries (MEDIUM Devices) ---------  */
@media screen and (max-width: 600px) {
    header{
        height: 100vh;
    }


    .header__socials, 
    .scroll__down {
        display: none;
    } 
    
}